import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SearchInput,
  Spinner,
} from "vapi-ui-common";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { AccessoryCommonLanguageItem } from "../../models/commonLanguage.model";
import useGetADSAccessory from "../../routes/commonLanguage/hooks/useGetADSAccessory";
import ImportByPartNumberCard from "./ImportByPartNumberCard";
import styles from "./ImportByPartNumberModal.module.scss";

export interface ImportByPartNumberModalProps {
  openImportModal: boolean;
  setOpenImportModal: (val: boolean) => void;
  isCommonLanguageDraft?: boolean;
  onClickInCommonDraft?: (item: AccessoryCommonLanguageItem) => void;
  onClickInAccessoryDraft?: (item: AccessoryItemVM) => void;
  uid?: string;
}

const ImportByPartNumberModal = ({
  openImportModal,
  setOpenImportModal,
  isCommonLanguageDraft,
  onClickInCommonDraft,
  onClickInAccessoryDraft,
  uid = uuidv4(),
}: ImportByPartNumberModalProps) => {
  const {
    searchText,
    setSearchText,
    commonLanguageItem,
    accessoryItem,
    onReset,
    isSearching,
  } = useGetADSAccessory({ uid, isCommonLanguageDraft });
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelected(e.currentTarget.checked);
  };

  const onClose = () => {
    setIsSelected(false);
    onReset();
    setOpenImportModal(false);
  };

  const onClick = () => {
    if (isCommonLanguageDraft && commonLanguageItem && onClickInCommonDraft) {
      onClickInCommonDraft(commonLanguageItem);
    } else if (accessoryItem && onClickInAccessoryDraft) {
      onClickInAccessoryDraft(accessoryItem);
    }
    onClose();
  };

  const onSearch = (val: string) => {
    setIsSelected(false);
    setSearchText(val);
  };

  return (
    <div data-testid="import-modal-comp">
      <Modal open={openImportModal} onClose={onClose}>
        <ModalHeader onClose={onClose}>
          <span data-testid="modal-header">Import by Part Number</span>
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <SearchInput
            className={styles.searchInput}
            placeholder="Search by Part Number"
            value={searchText}
            onSearch={onSearch}
            maxLength={14}
            isByClick
            isByEnter
          />
          {isSearching ? (
            <Spinner />
          ) : (
            <ImportByPartNumberCard
              title={
                isCommonLanguageDraft
                  ? commonLanguageItem?.title ?? ""
                  : accessoryItem?.title ?? ""
              }
              partNumber={
                isCommonLanguageDraft
                  ? commonLanguageItem?.adsId ?? ""
                  : accessoryItem?.adsId ?? ""
              }
              dealerCost={
                isCommonLanguageDraft
                  ? commonLanguageItem?.partsOnlyDealerCost ?? ""
                  : accessoryItem?.partsOnlyDealerCost ?? ""
              }
              msrp={
                isCommonLanguageDraft
                  ? commonLanguageItem?.msrp ?? ""
                  : accessoryItem?.msrp ?? ""
              }
              imageLink={
                isCommonLanguageDraft
                  ? commonLanguageItem?.images?.[0]?.image ?? ""
                  : accessoryItem?.images?.[0]?.image ?? ""
              }
              isSelected={isSelected}
              onChange={onChange}
            />
          )}
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            variant="transparent"
            onClick={onClose}
            data-testid="modal-footer-cancel-cta"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={onClick}
            disabled={!isSelected}
            data-testid="modal-footer-import-cta"
          >
            Import by Part Number
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImportByPartNumberModal;
