import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "vapi-ui-common";
import apolloClient from "../../../apolloClient";
import {
  GetAdsAccessoryCommonLanguageDocument,
  GetAdsAccessoryCommonLanguageQuery,
  GetAdsAccessoryCommonLanguageQueryVariables,
  GetAdsAccessoryDocument,
  GetAdsAccessoryQuery,
  GetAdsAccessoryQueryVariables,
} from "../../../gql/generated";
import logger from "../../../log";
import AccessoryItemVM from "../../../models/accessories/AccessoryItemVM";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";

interface Props {
  isCommonLanguageDraft?: boolean;
  uid: string;
}

const useGetADSAccessory = ({ isCommonLanguageDraft, uid }: Props) => {
  const { debounce } = useDebounce({ delay: 500 });
  const [searchText, setSearchText] = useState<string>("");
  const [commonLanguageItem, setCommonLanguageItem] =
    useState<AccessoryCommonLanguageItem | null>(null);
  const [accessoryItem, setAccessoryItem] = useState<AccessoryItemVM | null>(
    null
  );
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const onSearchCommonLanguage = useCallback(
    (adsPartNumber: string) => {
      if (!adsPartNumber.length || isSearching) return;

      debounce(async () => {
        try {
          setIsSearching(true);
          const { data } = await apolloClient.query<
            GetAdsAccessoryCommonLanguageQuery,
            GetAdsAccessoryCommonLanguageQueryVariables
          >({
            query: GetAdsAccessoryCommonLanguageDocument,
            variables: {
              adsPartNumber,
            },
            fetchPolicy: "network-only",
          });

          if (!data) {
            setCommonLanguageItem(null);
            throw new Error(
              "Error loading common language data - ads accessory by part number"
            );
          }

          const accessoryCLItemWithImages = {
            ...data.adsAccessoryCommonLanguage,
            images: data.adsAccessoryCommonLanguage.images?.map((item) => ({
              image: item?.image,
              isHero: item?.isHero,
              name: item?.name,
            })),
          };

          setCommonLanguageItem({
            ...accessoryCLItemWithImages,
            partNumber: data.adsAccessoryCommonLanguage.adsId,
          } as AccessoryCommonLanguageItem);
        } catch (error) {
          logger.error(error);
          toast.error(
            "Error loading common language data - ads accessory by part number"
          );
          setCommonLanguageItem(null);
        }
        setIsSearching(false);
      }, uid);
    },
    [uid]
  );

  const onSearchAccessory = useCallback(
    (adsPartNumber: string) => {
      if (!adsPartNumber.length || isSearching) return;

      debounce(async () => {
        try {
          setIsSearching(true);
          const { data } = await apolloClient.query<
            GetAdsAccessoryQuery,
            GetAdsAccessoryQueryVariables
          >({
            query: GetAdsAccessoryDocument,
            variables: {
              adsPartNumber,
            },
            fetchPolicy: "network-only",
          });

          if (!data) {
            setAccessoryItem(null);
            throw new Error(
              "Error loading accessory data - ads accessory by part number"
            );
          }

          const accessoryItemWithImages = {
            ...data.adsAccessory,
            images: data.adsAccessory.images?.map((item) => ({
              image: item?.image,
              isHero: item?.isHero,
              name: item?.name,
            })),
          };

          const accItem = new AccessoryItemVM({
            accessoryItem: accessoryItemWithImages,
          });

          setAccessoryItem(accItem);
        } catch (error) {
          logger.error(error);
          toast.error(
            "Error loading accessory data - ads accessory by part number"
          );
          setAccessoryItem(null);
        }
        setIsSearching(false);
      }, uid);
    },
    [uid]
  );

  useEffect(() => {
    if (isCommonLanguageDraft) {
      onSearchCommonLanguage(searchText);
    } else {
      onSearchAccessory(searchText);
    }
  }, [isCommonLanguageDraft, searchText]);

  const onReset = useCallback(() => {
    setSearchText("");
    if (isCommonLanguageDraft) {
      setCommonLanguageItem(null);
    } else {
      setAccessoryItem(null);
    }
  }, [isCommonLanguageDraft]);

  return {
    searchText,
    setSearchText,
    commonLanguageItem,
    accessoryItem,
    onReset,
    isSearching,
  };
};

export default useGetADSAccessory;
