import { action, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { convertToRichTextObject } from "vapi-ui-common";
import {
  AccessoryItem,
  ComLangRef,
  GetAccessoriesWithProductTypeQuery,
  GetCommonLanguageIdsQuery,
  GetLatestVersionQuery,
  RefItem,
} from "../../gql/generated";
import AccessoryItemVM, {
  GoLiveDateGQL,
} from "../../models/accessories/AccessoryItemVM";
import {
  AccessoriesLangMap,
  GoLiveDateItemgMap,
  ProductTypeLangMap,
} from "../../models/accessories/accessories.models";
import { Language } from "../../models/user/user.model";
import VehicleModelsResponse from "../../models/vehicleModels/VehicleModelsResponse";
import sortBy from "../../utils/sortBy";

export type AccessoriesLangMapDataType = {
  enData?: GetAccessoriesWithProductTypeQuery;
  esData?: GetAccessoriesWithProductTypeQuery;
  getESData?: boolean;
  enVersionData?: GetLatestVersionQuery;
  comLangIdData?: GetCommonLanguageIdsQuery;
};

class AccessoryStore {
  @observable allData: AccessoryItemVM[] = [];

  @observable filteredData: AccessoryItemVM[] = [];

  @observable modelData: VehicleModelsResponse[] = [];

  @observable sortField: string = "";

  @observable isLoaded = false;

  @observable isReversed = false;

  @observable readOnly = false;

  @observable productTypes: RefItem[] = [];

  @observable lastSyncDate: string = "";

  @observable lastNATPublishedDate: string = "";

  @observable defaultLanguage: Language = Language.EN;

  @observable enSourceVersion: string = "";

  @observable enCurrentVersion: string = "";

  @observable accessoriesLangMap: AccessoriesLangMap = {};

  @observable productTypeLangMap: ProductTypeLangMap = {};

  @observable isReloadingDraft: boolean = false;

  @observable commonLanguageIds: ComLangRef[] = [];

  @observable commonLanguageUpdatedIds: ComLangRef[] = [];

  @observable goLiveDateLangEnMapData: GoLiveDateItemgMap = {};

  @observable hasADSUpdates?: boolean | null = false;

  @action reset = () => {
    this.allData = [];
    this.filteredData = [];
    this.isLoaded = false;
    this.readOnly = false;
    this.accessoriesLangMap = {};
    this.productTypeLangMap = {};
    this.goLiveDateLangEnMapData = {};
  };

  addEmptyItem = () => {
    const newItem = new AccessoryItemVM({ uid: uuidv4() });
    newItem.id = uuidv4();
    this.allData = [newItem, ...this.allData];
    this.filteredData = [newItem, ...this.filteredData];

    const map = this.accessoriesLangMap;
    this.accessoriesLangMap = {
      ...map,
      [newItem.id]: {
        langs: {
          [Language.EN]: newItem,
        },
        defaultItem: newItem,
        uid: newItem.uid,
        id: newItem.id,
      },
    };
  };

  @action deleteItem = (item: AccessoryItemVM) => {
    this.allData = this.allData.filter((i) => i.uid !== item.uid);
    this.filteredData = this.filteredData.filter((i) => i.uid !== item.uid);
  };

  // used because of trouble using a fn in checkboxes checked attribute hidden in arrays
  @action forceUpdate = () => {
    this.allData = this.allData.map((x) => x);
    this.filteredData = this.filteredData.map((x) => x);
  };

  @action setSort = (prop: string) => {
    if (this.sortField === prop) {
      this.isReversed = !this.isReversed;
    } else {
      this.isReversed = false;
    }
    this.sortField = prop;
    this.filteredData = this.filteredData
      .slice()
      .sort(sortBy(this.sortField, this.isReversed, false));
  };

  @action addNewAccessories = (items: AccessoryItemVM[]) => {
    this.allData = [...items, ...this.allData];
    this.filteredData = [...items, ...this.filteredData];

    const accMap = this.accessoriesLangMap;
    items.forEach((item) => {
      accMap[item.id] = {
        langs: { [Language.EN]: item },
        defaultItem: item,
        id: item.id,
        uid: item.uid,
      };
    });
  };

  compareFilteredItems = (
    searchTextParam: string,
    item: string | undefined | null
  ) => {
    return (
      item &&
      convertToRichTextObject(item)
        .text.toLowerCase()
        .indexOf(searchTextParam) !== -1
    );
  };

  @action filterItems = (
    searchTextParam: string,
    productTypeFiltersParam: string[],
    inProgressFilterParam: boolean,
    syncChangesFilterParam: boolean = false,
    isActiveFilterParam?: boolean,
    dioFilterParam?: boolean,
    ppoFilterParam?: boolean
  ) => {
    const loweredSearchText = searchTextParam.toLowerCase();
    const ppoAndDio = dioFilterParam && ppoFilterParam;
    const filteredAcc = this.allData.filter((item) => {
      if (!item.revId) {
        return true;
      }
      if (
        productTypeFiltersParam.length &&
        productTypeFiltersParam.indexOf(item.productType || "") === -1
      ) {
        return false;
      }

      // if dio and ppo are selected without progress status
      if (ppoAndDio && !isActiveFilterParam && !inProgressFilterParam) {
        return true;
      }

      // filter both ppo and dio clicked and active
      if (ppoAndDio && isActiveFilterParam && !item.inactive) {
        return true;
      }

      // filter both ppo and dio clicked and inctive
      if (ppoAndDio && inProgressFilterParam && item.inactive) {
        return true;
      }

      // filter based on dio or ppo
      if (
        (dioFilterParam &&
          item.installPoint &&
          !item.installPoint.includes("DIO")) ||
        (ppoFilterParam &&
          item.installPoint &&
          !item.installPoint.includes("PPO"))
      ) {
        return false;
      }

      // filter based on active or inprogress
      if (
        (inProgressFilterParam && item.inactive !== inProgressFilterParam) ||
        (isActiveFilterParam && item.inactive === isActiveFilterParam)
      ) {
        return false;
      }

      const accMap = this.accessoriesLangMap[item.id];

      if (syncChangesFilterParam) {
        let hasChanges = false;
        if (accMap) {
          Object.values(accMap.langs).forEach((acc) => {
            if (acc.changedAttributes?.length) {
              hasChanges = true;
            }
          });
        }
        if (!hasChanges) {
          return false;
        }
      }

      if (!loweredSearchText) {
        return true;
      }

      if (accMap) {
        let hasMatch = false;
        Object.values(accMap.langs).forEach((acc) => {
          if (
            this.compareFilteredItems(loweredSearchText, acc.title) ||
            this.compareFilteredItems(loweredSearchText, acc.copy) ||
            this.compareFilteredItems(loweredSearchText, acc.disclosure) ||
            this.compareFilteredItems(loweredSearchText, acc.installPoint) ||
            this.compareFilteredItems(loweredSearchText, acc.ppoCode)
          ) {
            hasMatch = true;
          }
        });
        return hasMatch;
      }

      return false;
    });

    this.filteredData = filteredAcc;
  };

  @action updateData = (
    data: AccessoryItemVM[],
    newFilteredData?: AccessoryItemVM[],
    newItem?: AccessoryItemVM
  ) => {
    this.allData = data;

    if (newFilteredData) {
      this.filteredData = newFilteredData;
    } else {
      this.filteredData = data;
    }

    if (newItem) {
      this.accessoriesLangMap[newItem.id] = {
        langs: {
          [Language.EN]: newItem,
        },
        defaultItem: newItem,
        uid: newItem.uid,
        id: newItem.id,
      };
    }
  };

  @action filterItemsGST = (
    searchTextParam: string,
    inProgressFilterParam: boolean,
    isActiveFilterParam: boolean
  ) => {
    const loweredSearchText = searchTextParam.toLowerCase();
    const filteredAcc = this.allData.filter((item) => {
      if (!item.revId) {
        return true;
      }

      if (
        ![
          item.abb,
          item.supplier,
          item.description,
          item.description_es,
          item.disclaimer,
          item.disclaimer_es,
          item.title,
          item.title_es,
        ].some((text) => this.compareFilteredItems(loweredSearchText, text))
      ) {
        return false;
      }

      if (inProgressFilterParam && !item.inactive) {
        return false;
      }

      if (isActiveFilterParam && Boolean(item.inactive)) {
        return false;
      }

      return true;
    });

    this.filteredData = filteredAcc;
  };

  @action sortAlphabetically = () => {
    this.allData = this.allData
      .sort((a, b) => {
        const aLower = convertToRichTextObject(a.title).text.toLowerCase();
        const bLower = convertToRichTextObject(b.title).text.toLowerCase();
        if (aLower < bLower) {
          return -1;
        }
        if (aLower > bLower) {
          return 1;
        }
        return 0;
      })
      .map((item, index) => {
        return {
          ...item,
          sortOrder: index + 1,
        };
      });
  };

  addProductType = (item: RefItem) => {
    this.productTypes = [...this.productTypes, item];
    const pMap = this.productTypeLangMap;
    pMap[item.id] = {
      id: item.id,
      langs: {
        [Language.EN]: item,
      },
    };
    this.productTypeLangMap = pMap;
  };

  addProductTypes = (items: RefItem[]) => {
    this.productTypes = [...this.productTypes, ...items];
    const pMap = this.productTypeLangMap;
    items.forEach((item) => {
      pMap[item.id] = {
        id: item.id,
        langs: {
          [Language.EN]: item,
        },
      };
    });
    this.productTypeLangMap = pMap;
  };

  @action updateLastSyncDate = (lastSyncDate: string) => {
    this.lastSyncDate = lastSyncDate;
  };

  @action cleanCommonLanguageUpdatedIds = () => {
    this.commonLanguageUpdatedIds = [];
  };

  @action setDefaultLanguage = (language: Language) => {
    this.defaultLanguage = language;
  };

  setAccessoriesLangMapData = (
    accessoriesLangMapData: AccessoriesLangMapDataType
  ) => {
    this.setAccessoriesLangEnMapData(accessoriesLangMapData);
    this.setAccessoriesLangEsMapData(accessoriesLangMapData);
    this.setRestOfData(accessoriesLangMapData);
    this.setGoLiveDateLangEnMapData(
      accessoriesLangMapData.enData?.accessories?.goLivedDates
    );
  };

  @action setGoLiveDateLangEnMapData = (
    glDateList: GoLiveDateGQL[] | null | undefined
  ) => {
    const map: GoLiveDateItemgMap = this.goLiveDateLangEnMapData;

    glDateList?.forEach((glDate) => {
      if (!glDate) return;

      if (map[glDate.id]) {
        map[glDate.id].goLiveDate = glDate.goLiveDate;
        map[glDate.id].revId = glDate.revId;
        return;
      }

      map[glDate.id] = {
        id: glDate.id,
        goLiveDate: glDate.goLiveDate,
        revId: glDate.revId,
      };
    });

    this.goLiveDateLangEnMapData = map;
  };

  setAccessoriesLangEnMapData = ({ enData }: AccessoriesLangMapDataType) => {
    if (!enData) return;

    const accMap = this.accessoriesLangMap;
    const enAccessories =
      (enData.accessories?.accessories as AccessoryItem[]) || [];

    // todo: we need to drop the allData and filteredData variables; this is a temporary workaround until we drop all the variables
    const newData: AccessoryItemVM[] = [];

    enAccessories.forEach((item, index) => {
      const newItem = new AccessoryItemVM({
        accessoryItem: item,
        sortOrder: index + 1,
        uid: uuidv4(),
      });
      newData.push(newItem);

      accMap[newItem.id] = {
        id: newItem.id,
        uid: newItem.uid,
        langs: { [Language.EN]: newItem },
        defaultItem: item,
      };
    });

    const proMap = this.productTypeLangMap;
    const enProductTypes = enData.refItems?.refItems || ([] as RefItem[]);

    enProductTypes.forEach((pt) => {
      proMap[pt.id] = { langs: { [Language.EN]: pt }, id: pt.id };
    });

    this.allData = newData;
    this.filteredData = newData;
    this.productTypes = enProductTypes;
  };

  updateProductTypes = (arr: RefItem[]) => {
    const newArr = [...arr];
    this.productTypes = newArr;
  };

  setAccessoriesLangEsMapData = ({
    esData,
    getESData,
  }: AccessoriesLangMapDataType) => {
    if (!getESData || !esData) return;

    const esAccessories =
      (esData.accessories?.accessories as AccessoryItem[]) || [];

    const esAccessoriesMap: { [id: string]: AccessoryItem } = {};
    esAccessories.forEach((acc) => {
      esAccessoriesMap[acc.id] = acc;
    });

    const accMap = this.accessoriesLangMap;
    Object.keys(accMap).forEach((id) => {
      const newItem = new AccessoryItemVM({
        accessoryItem: esAccessoriesMap[id] ?? accMap[id].defaultItem,
      });

      // if the spanish accessory doesnt exist then we want to set the revid to be empty so we know when saving the accessory we have to create it first
      if (!esAccessoriesMap[id]) {
        newItem.revId = "";
      }

      newItem.sortOrder = accMap[id].langs[Language.EN].sortOrder;

      accMap[id].langs[Language.ES] = newItem;
    });

    const esProductTypes = esData.refItems?.refItems || ([] as RefItem[]);

    const esProductTypesMap: { [id: string]: RefItem } = {};
    esProductTypes.forEach((pt) => {
      esProductTypesMap[pt.id] = pt;
    });

    const proMap = this.productTypeLangMap;
    Object.keys(proMap).forEach((id) => {
      if (esProductTypesMap[id]) {
        proMap[id].langs[Language.ES] = esProductTypesMap[id];
      } else {
        const enPt = proMap[id].langs[Language.EN];
        proMap[id].langs[Language.ES] = {
          ...enPt,
          revId: "",
        };
      }
    });
  };

  /**
   * Sets the values for readOnly, lastSyncDate, lastNATPublishedDate (should be changed to published date), english source version and english current version
   * @param AccessoriesLangMapDataType
   */
  setRestOfData = ({
    enData,
    esData,
    enVersionData,
    getESData,
    comLangIdData,
  }: AccessoriesLangMapDataType) => {
    this.isLoaded = true;
    this.readOnly = getESData
      ? typeof esData?.accessories?.header?.publishedDate === "string"
      : typeof enData?.accessories?.header?.publishedDate === "string";
    this.lastSyncDate =
      (getESData
        ? esData?.accessories?.header?.lastSyncDate
        : enData?.accessories?.header?.lastSyncDate) || "";
    // lastNATPublishedDat: for gst => lastNATPublishedDate; for ntnl english => will be undefined/empty string; for ntnl spanish => will be last english published date
    this.lastNATPublishedDate =
      (getESData
        ? enVersionData?.latestVersion?.publishedDate
        : enData?.accessories?.header?.lastNATPublishedDate) || "";

    if (getESData && esData && enVersionData) {
      this.enSourceVersion = (
        esData?.accessories?.header?.sourceVersion || ""
      ).toString();
      this.enCurrentVersion = (
        enVersionData?.latestVersion?.latestVersion || ""
      ).toString();
    }

    this.commonLanguageIds = comLangIdData?.getComLangIds?.comLangIds ?? [];

    this.commonLanguageUpdatedIds =
      ((getESData
        ? esData?.accessories?.header?.comLangIds
        : enData?.accessories?.header?.comLangIds) as
        | ComLangRef[]
        | undefined
        | null) ?? [];

    this.hasADSUpdates = enData?.accessories?.header?.hasADSUpdates;
  };

  @action setIsReloadingDraft = (isReloading: boolean) => {
    this.isReloadingDraft = isReloading;
  };

  @action setHasADSUpdates = (hasADSUpdates: boolean) => {
    this.hasADSUpdates = hasADSUpdates;
  };
}

export default AccessoryStore;
