import React from "react";
import {
  Button,
  HeaderCell,
  HeaderRow,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  Thead,
} from "vapi-ui-common";
import { toast } from "react-toastify";
import { ChangeLogTypes } from "../../models/accessories/changeLog.model";
import {
  AclChange,
  Change,
  useCompareAccToAdsChangesQuery,
  useCompareAclToAdsQuery,
} from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { ADSRowChanges } from "./components/ADSRowChanges";
import styles from "./compareAccToADSModal.module.scss";
import logger from "../../log";
import { AccessoryCommonLanguageItem } from "../../models/commonLanguage.model";

export interface CompareAccToADSModalProps<
  A extends AccessoryItemVM | AccessoryCommonLanguageItem
> {
  brand: string;
  team: string;
  region: string;
  lang?: string;
  seriesId?: string;
  modelYear?: number;
  accessory?: A;
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  saveAccessoryItem?: (item: A) => void;
}

const CompareAccToADSModal = <
  A extends AccessoryItemVM | AccessoryCommonLanguageItem
>({
  brand,
  team,
  region,
  lang,
  seriesId,
  modelYear,
  accessory,
  openModal,
  setOpenModal,
  saveAccessoryItem,
}: CompareAccToADSModalProps<A>) => {
  const { data: aclData, loading: aclLoading } = useCompareAclToAdsQuery({
    skip: !openModal || !accessory?.id || !!lang || !!seriesId || !!modelYear,
    fetchPolicy: "network-only",
    variables: {
      brand,
      team,
      region,
      compareAclToAdsId: accessory?.id!,
    },
    onError: (error) => {
      logger.error(error);
      toast.error("Error loading AMS changes.");
    },
    onCompleted: (data) => {
      if (!data.compareACLToADS?.changes) {
        logger.error(new Error("Empty data on loading AMS changes."));
        toast.error("Error loading AMS changes.");
      }
    },
  });
  const { data: accData, loading: accLoading } = useCompareAccToAdsChangesQuery(
    {
      skip: !openModal || !accessory?.id || !lang || !seriesId || !modelYear,
      fetchPolicy: "network-only",
      variables: {
        brand,
        team,
        region,
        lang: lang!,
        seriesId: seriesId!,
        modelYear: modelYear!,
        id: accessory?.id!,
      },
      onError: (error) => {
        logger.error(error);
        toast.error("Error loading AMS changes.");
      },
      onCompleted: (data) => {
        if (!data.compareAccToADS?.changes) {
          logger.error(new Error("Empty data on loading AMS changes."));
          toast.error("Error loading AMS changes.");
        }
      },
    }
  );
  const changes =
    aclData?.compareACLToADS?.changes ?? accData?.compareAccToADS?.changes;
  const loading = aclLoading || accLoading;

  const onClose = () => {
    setOpenModal(false);
  };

  const onSave = (isUnlinked: boolean = false) => {
    if (!accessory || !changes) {
      return;
    }

    const acc = accessory;

    changes.forEach((item: Change | AclChange) => {
      switch (item.changeType) {
        case ChangeLogTypes.TITLE:
          acc.title = item.after ?? "";
          break;

        case ChangeLogTypes.COPY:
          acc.copy = item.after ?? "";
          break;

        case ChangeLogTypes.DISCLOSURE:
          acc.disclosure = item.after ?? "";
          break;

        case ChangeLogTypes.PARTS_ONLY_DEALER_COST:
          acc.partsOnlyDealerCost = item.after ?? "";
          break;

        case ChangeLogTypes.MSRP:
          acc.msrp = item.after ?? "";
          break;

        default:
      }
    });

    if (isUnlinked) {
      acc.adsId = null;
    }

    acc.hasADSUpdates = false;
    if (saveAccessoryItem) {
      saveAccessoryItem(acc);
    }
    onClose();
  };

  return (
    <div data-testid="compare-acc-ads-modal-comp">
      <Modal
        className={styles.modalContainer}
        open={openModal}
        onClose={onClose}
      >
        <ModalHeader onClose={onClose}>
          <span data-testid="modal-header">Changes from AMS</span>
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          {loading || !changes ? (
            <Spinner short />
          ) : (
            <Table fullWidth>
              <Thead>
                <HeaderRow>
                  <HeaderCell>Title</HeaderCell>
                  <HeaderCell>Change Type</HeaderCell>
                  <HeaderCell>Changes</HeaderCell>
                </HeaderRow>
              </Thead>
              <tbody>
                <ADSRowChanges changes={changes} />
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            variant="transparent"
            onClick={() => onSave(true)}
            className={styles.unlinkButton}
            data-testid="button-unlink-ams-cta"
          >
            Unlink from AMS
          </Button>
          <Button
            variant="transparent"
            onClick={onClose}
            data-testid="button-cancel-cta"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onSave()}
            data-testid="button-accept-all-cta"
          >
            Accept All
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CompareAccToADSModal;
