import React, { useState } from "react";
import { Button, Header, Modal, PublishModal } from "vapi-ui-common";
import { NavLink, useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { usePublishAclDraftMutation } from "../../../../gql/generated";
import useStores from "../../../../stores/useStores";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../../constants/Constants";
import handleErrorResponse from "../../../../utils/errorHandlingUtils";

const DraftHeader = () => {
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const { userStore, commonLanguageStore } = useStores();
  const history = useHistory();

  const [publishDraft] = usePublishAclDraftMutation({
    variables: {
      input: {
        brand: userStore.brand,
        region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
        team: ACCESSORY_TEAM,
      },
    },
  });

  const handlePublish = async () => {
    try {
      await trackPromise(publishDraft());
      toast.success("Draft published successfully");

      history.push("/commonLanguage/published");
    } catch (error) {
      handleErrorResponse(error, (error as Error).message);
    }
  };

  const isValidToPublish = () => {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of commonLanguageStore.data) {
        item.isValid(userStore.isGstUser());
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <Header moduleTitle="Common Language" moduleSubTitle="Draft">
      <NavLink to="/commonLanguage/changeLog/draft?exitVersion=draft">
        <Button variant="transparent">Change Log</Button>
      </NavLink>
      <Button
        variant="primary"
        onClick={() => setPublishModalOpen(true)}
        disabled={!isValidToPublish()}
      >
        Publish
      </Button>
      <Modal open={publishModalOpen} onClose={() => setPublishModalOpen(false)}>
        <PublishModal
          close={() => setPublishModalOpen(false)}
          publishData={handlePublish}
        />
      </Modal>
    </Header>
  );
};

export default observer(DraftHeader);
