/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { TableCell, TableRow } from "vapi-ui-common";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import AmsButton from "../../../../components/AmsButton";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";
import ContextMenuCell from "../../../../components/tableCells/tableCells/ContextMenuCell/ContextMenuCell";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import useRowHandlers from "../../hooks/useRowHandlers";
import styles from "./commonLanguageTable.module.scss";
import CompareAccToADSModal from "../../../../components/CompareAccToADSModal";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../../constants/Constants";

interface CLLeftRowProps {
  item: AccessoryCommonLanguageItem;
  readonly?: boolean;
}

const CLLeftRow = ({ item, readonly }: CLLeftRowProps) => {
  const { handleRichTextChange, copyItem, deleteItem, saveCommonLanguage } =
    useRowHandlers(item);
  const { userStore, teamStore } = useStores();
  const [openAmsModal, setOpenAmsModal] = useState<boolean>(false);
  const [amsAcc, setAmsAcc] = useState<AccessoryCommonLanguageItem>();

  const onAmsClick = () => {
    setOpenAmsModal(true);
    setAmsAcc(item);
  };

  const saveAccessoryItem = () => {
    saveCommonLanguage();
  };

  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    !readonly &&
    item.adsId;

  return (
    <>
      <TableRow>
        {!readonly && (
          <TableCell className={cx(styles.cell, styles.actionButtons)}>
            <ContextMenuCell
              disabled={readonly}
              description={item.title}
              itemType="Common Language"
              deleteItem={deleteItem}
              copyItem={copyItem}
              deleteItemText={item.publishedDate ? "Archive" : "Delete"}
            />
          </TableCell>
        )}

        {/* Accessory Name */}
        <TableCell
          className={cx(styles.cell, styles.textArea, {
            [styles.syncAmsAdsCell]: showSyncAmsAds,
          })}
        >
          {item.isArchived && <StatusIndicator>Archived</StatusIndicator>}
          <AccessoryRichText
            value={item.title}
            onBlur={handleRichTextChange("title")}
            disabled={readonly}
            required={!item.title}
          />
          {showSyncAmsAds && (
            <AmsButton onClick={onAmsClick} hasUpdates={item.hasADSUpdates} />
          )}
        </TableCell>

        {/* Copy */}
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.copy}
            onBlur={handleRichTextChange("copy")}
            disabled={readonly}
            required={!item.copy}
          />
        </TableCell>
      </TableRow>
      <CompareAccToADSModal
        openModal={openAmsModal}
        setOpenModal={setOpenAmsModal}
        accessory={amsAcc}
        brand={userStore.brand}
        region={userStore.isGstUser() ? GST_REGION : NATIONAL_REGION}
        team={ACCESSORY_TEAM}
        saveAccessoryItem={saveAccessoryItem}
      />
    </>
  );
};

export default observer(CLLeftRow);
