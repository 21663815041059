/* eslint-disable react/jsx-one-expression-per-line */
import { observer } from "mobx-react-lite";
import React from "react";
import {
  HeaderCell,
  Table,
  TableRow,
  Thead,
  TwoTableWrapper,
} from "vapi-ui-common";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import CLLeftRow from "./CLLeftRow";
import CLRightRow from "./CLRightRow";
import styles from "./commonLanguageTable.module.scss";

interface CLTableProps {
  readonly?: boolean;
  archived?: boolean;
}

const CLTable = observer(({ readonly, archived }: CLTableProps) => {
  const { commonLanguageStore, userStore } = useStores();

  const handleSort = (key: keyof AccessoryCommonLanguageItem) => () => {
    commonLanguageStore.setSort(key);
  };

  return (
    <TwoTableWrapper>
      <Table fullWidth className={styles.sticky}>
        <Thead>
          <TableRow>
            {!readonly && <HeaderCell>{/* Action buttons */}</HeaderCell>}
            <HeaderCell onClick={handleSort("title")}>
              Accessory Name
            </HeaderCell>
            <HeaderCell onClick={handleSort("copy")}>Copy</HeaderCell>
          </TableRow>
        </Thead>

        <tbody>
          {commonLanguageStore.filteredData.map((item) => (
            <CLLeftRow key={item.uid} item={item} readonly={readonly} />
          ))}
        </tbody>
      </Table>
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell onClick={handleSort("disclosure")}>
              Disclosure(s)
            </HeaderCell>
            <HeaderCell onClick={handleSort("productType")}>
              Product Type
            </HeaderCell>
            <HeaderCell>Install Point</HeaderCell>
            <HeaderCell onClick={handleSort("ppoCode")}>PPO Code</HeaderCell>
            <HeaderCell onClick={handleSort("warranty")}>Warranty</HeaderCell>
            <HeaderCell>Required/Conflicts</HeaderCell>
            <HeaderCell onClick={handleSort("partNumber")}>
              Part Number
            </HeaderCell>
            <HeaderCell onClick={handleSort("partsOnlyDealerCost")}>
              Dealer Cost
            </HeaderCell>
            {!userStore.isLexusUser() && (
              <HeaderCell>Parts Only MSRP</HeaderCell>
            )}
            <HeaderCell>{/* Row Level Information */}</HeaderCell>
            {readonly && (
              <HeaderCell>{archived ? "Archive" : "Publish"} Date</HeaderCell>
            )}
          </TableRow>
        </Thead>

        <tbody>
          {commonLanguageStore.filteredData.map((item, index) => (
            <CLRightRow
              key={item.uid}
              index={index}
              item={item}
              readonly={readonly}
            />
          ))}
        </tbody>
      </Table>
    </TwoTableWrapper>
  );
});

export default CLTable;
